import { format } from 'date-fns';
import { DateTime } from 'luxon';

import { Service } from '../../../../store/services/services.types';
import { Building } from '../../../../types/buildings';
import { DATE_FORMAT_BE, TIME_FORMAT_BE } from '../../../../utils/constants';
import { TIME_SLOTS_EDITOR_TYPES } from '../ScheduleForms.constants';
import {
  AddScheduleFormValues,
  AddTimeSlotsRequestValues,
  LocationServices,
  RepeatType,
} from './AddScheduleForm.types';

export const ADD_SCHEDULE_FORM_DEFAULT_VALUES: AddScheduleFormValues = {
  services_buildings: [],
  start_date: null,
  start_time: null,
  end_date: null,
  end_time: null,
  no_end_date: false,
  weekdays: [],
  timeslots: [],
  repeat_every: 1,
  repeat_type: RepeatType.WEEK,
  repeat_checkbox: false,
  min_duration: 0.5,
};

export const mapBuildingsForSelect = (buildings: { result: Building[] }) => {
  return [...buildings.result]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((building: Building) => ({
      label: `${building.code} ${building.name}`,
      value: building.id,
    }));
};

export const mapServicesForSelect = (services: { result: Service[] }) => {
  return [...services.result]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((service: Service) => {
      return {
        label: service.name,
        value: service.id,
        min_duration: service.time_slot_duration_internal,
      };
    });
};

export const prepareDataForRequest = (
  addScheduleFormValues: AddScheduleFormValues,
  editorType: TIME_SLOTS_EDITOR_TYPES,
  serviceOrSpecialistId: string,
  isSpecialistPage: boolean | undefined,
): AddTimeSlotsRequestValues => {
  const {
    services_buildings,
    start_date,
    end_date,
    weekdays,
    timeslots,
    repeat_checkbox,
    repeat_every,
    repeat_type,
  } = addScheduleFormValues;

  const request: AddTimeSlotsRequestValues = {
    end_date: format(end_date as Date, DATE_FORMAT_BE),
    services_buildings: isSpecialistPage
      ? (services_buildings as LocationServices[])
      : (services_buildings as string[]).map((building) => ({
          building,
          services: [serviceOrSpecialistId],
        })),
    specialist_id: isSpecialistPage ? serviceOrSpecialistId : undefined,
    start_date: format(start_date as Date, DATE_FORMAT_BE),
    repeat_checkbox,
    repeat_every,
    repeat_type,
    status: editorType,
    timeslots: timeslots.map((item) => ({
      start_time: format(item.start_time as Date, TIME_FORMAT_BE),
      end_time: format(item.end_time as Date, TIME_FORMAT_BE),
    })),
    weekdays,
  };

  return request;
};

export const createTime = (dt: DateTime) =>
  DateTime.fromISO(`1970-01-01T${dt.toISOTime()}`);
