import { BaseTabConfig } from '../../../components/BaseTabs/BaseTabs';
import { Service } from '../../../store/services/services.types';
import { PackageList } from '../../Packages/List/PackageList';
import { ScheduleList } from '../../Schedule/List/ScheduleList';
import { SCHEDULE_PAGES } from '../../Schedule/List/ScheduleList.constants';
import {
  CONCIERGE_SERVICE_CATEGORY,
  FITNESS_AND_WELLNESS_CATEGORY,
  FITNESS_AND_WELLNESS_SERVICE_TYPE_GYM,
  FITNESS_AND_WELLNESS_SERVICE_TYPE_POOL,
  HOME_SERVICES_CATEGORY,
  HOME_SERVICES_SERVICE_TYPE_HOME_REPAIR,
} from '../Editor/ServiceEditor.constant';
import { ServiceInfo } from './ServiceInfo/ServiceInfo';

export enum SERVICE_DETAILS_TABS {
  SERVICE_INFO = 'service_info',
  PACKAGES = 'packages',
  SERVICES_SCHEDULE = 'services_schedule',
}

export const getServiceDetailsTabsConfig = (
  currentService: Service,
): BaseTabConfig[] => {
  let shouldShowPackages = false;

  if (
    (currentService.category.name === FITNESS_AND_WELLNESS_CATEGORY &&
      ![
        FITNESS_AND_WELLNESS_SERVICE_TYPE_POOL,
        FITNESS_AND_WELLNESS_SERVICE_TYPE_GYM,
      ].includes(currentService.service_type.name)) ||
    (currentService.category.name === HOME_SERVICES_CATEGORY &&
      ![HOME_SERVICES_SERVICE_TYPE_HOME_REPAIR].includes(
        currentService.service_type.name,
      ))
  ) {
    shouldShowPackages = true;
  }

  const needSpecialist = currentService?.service_type.need_specialist;
  const isConciergeService =
    currentService.category.name === CONCIERGE_SERVICE_CATEGORY;

  const serviceDetailsTabs = [
    {
      value: SERVICE_DETAILS_TABS.SERVICE_INFO,
      label: 'Service info',
      component: () => <ServiceInfo isConciergeService={isConciergeService} />,
    },
  ];

  if (shouldShowPackages) {
    serviceDetailsTabs.push({
      value: SERVICE_DETAILS_TABS.PACKAGES,
      label: 'Packages',
      component: () => <PackageList />,
    });
  }

  if (!needSpecialist && !isConciergeService) {
    serviceDetailsTabs.push({
      value: SERVICE_DETAILS_TABS.SERVICES_SCHEDULE,
      label: 'Services schedule',
      component: () => (
        <ScheduleList
          schedulePageType={SCHEDULE_PAGES.SERVICE}
          id={currentService.id}
        />
      ),
    });
  }

  return serviceDetailsTabs;
};
