import { Controller, useFormContext } from 'react-hook-form';

import { Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import * as S from './FormDatePicker.styled';

interface FormDatePickerProps {
  name: string;
  label: string;
  minDate?: Date;
  disabled?: boolean;
  shouldDisableDate?: (day: Date) => boolean;
  onChange?: (value: Date) => void;
}

export const FormDatePicker = ({
  name,
  label,
  minDate,
  disabled,
  shouldDisableDate,
  onChange,
}: FormDatePickerProps) => {
  const { control } = useFormContext();

  return (
    <S.Container>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <DatePicker
                defaultValue={null}
                disabled={disabled}
                label={label}
                minDate={minDate || new Date()}
                shouldDisableDate={shouldDisableDate}
                slotProps={{
                  field: {
                    clearable: true,
                  },
                  textField: {
                    onBeforeInput: (e) => {
                      e.preventDefault();
                    },
                  },
                }}
                {...field}
                onChange={(value) => {
                  if (onChange) {
                    onChange(value as Date);
                  }
                  field.onChange(value);
                }}
                onError={() => {
                  field.onBlur();
                }}
                onAccept={() => {
                  field.onBlur();
                }}
              />
              {error && (
                <Typography
                  variant="helperText"
                  color="error"
                  className="error-message"
                >
                  <>{error?.message}</>
                </Typography>
              )}
            </>
          )}
        />
      </LocalizationProvider>
    </S.Container>
  );
};
