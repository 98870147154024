import styled from '@emotion/styled/macro';

export const LocationWrapper = styled.div`
  padding-bottom: 12px;
  border-top: 1px solid ${(props: any) => props.theme.palette.grey[400]};
  border-bottom: 1px solid ${(props: any) => props.theme.palette.grey[400]};

  .MuiAlert-root {
    margin-top: 10px;
  }
`;

export const FormItem = styled.div`
  font-family: 'GraphikMedium', sans-serif;
  margin-bottom: 24px;
`;

export const FormItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'GraphikMedium', sans-serif;
  font-size: 20px;
  margin-bottom: 24px;
  padding-top: 24px;
`;

export const DateAndTimeWrapper = styled.div``;

export const DoubleFormItem = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;

  & > div {
    width: 240px;
  }

  & > div:first-of-type {
    margin-right: 20px;
  }
`;

export const DatePickerWrapper = styled.div`
  width: 240px;

  & > div:nth-of-type(2) {
    margin-top: 10px;
  }
`;

export const RepeatEveryWrapper = styled.div`
  margin-bottom: 24px;
`;

export const SelectedInterval = styled.div`
  height: 53px;
  display: flex;
  padding: 0 110px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background-color: ${(props: any) => props.theme.palette.grey.greyF3};

  & > span {
    font-size: 18px;
    font-family: 'GraphikMedium', sans-serif;
  }
`;
