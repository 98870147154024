import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox, FormControlLabel, Typography } from '@mui/material';

import * as S from './FormCheckbox.styled';

interface FormCheckboxProps {
  name: string;
  label: string;
  size?: number;
  onChange?: () => void;
}

export const FormCheckbox = ({
  name,
  label,
  size = 28,
  onChange,
}: FormCheckboxProps): ReactElement => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <S.Container size={size}>
      <FormControlLabel
        label={label}
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={field.value}
                onChange={(...params) => {
                  field.onChange(...params);

                  if (onChange) {
                    onChange();
                  }
                }}
              />
            )}
          />
        }
      />
      {errors[name] && (
        <Typography
          variant="helperText"
          color="error"
          className="error-message"
        >
          <>{errors?.[name]?.message}</>
        </Typography>
      )}
    </S.Container>
  );
};
