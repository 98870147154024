import styled from '@emotion/styled/macro';

export const Container = styled.div`
  position: relative;

  fieldset {
    border-color: black;
  }

  .error-message {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
  }

  .clearButton {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: red;
    transform: translateX(-75%);

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;
