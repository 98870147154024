import { Box, Grid, Paper } from '@mui/material';

import Close from 'assets/icons/cross.svg';
import PackageBox from 'assets/package-box.svg';

import GoldLabel from 'components/GoldLabel/GoldLabel';

import { PERMISSIONS } from '../../../constants/Roles';
import { useHasPermissions } from '../../../hooks/useHasPermissions';
import { Package } from '../../../store/packages/packages.types';
import { TrancatedString } from '../../../styles/common.styled';
import * as S from './PackageCard.styled';

interface PackageCardProps {
  package: Package;
  openDeleteModal: (id: string) => void;
}

export const PackageCard = ({
  package: { price, sessions_amount, id, name, expires_at },
  openDeleteModal,
}: PackageCardProps) => {
  const canUsePackagesAction = useHasPermissions(
    PERMISSIONS.CAN_USE_PACKAGE_ACTIONS,
  );

  return (
    <Grid item={true} xs={4} lg={3}>
      <S.Container>
        <Paper sx={{ padding: '16px' }} elevation={1}>
          {canUsePackagesAction && (
            <S.DeletePackage
              src={Close}
              alt="close"
              width={24}
              height={24}
              onClick={() => openDeleteModal(id)}
            />
          )}
          <Box>
            <S.BoxImage src={PackageBox} alt="package" />
            <S.Name title={name}>
              <TrancatedString>{name}</TrancatedString>
            </S.Name>
            <S.SessionsCount>
              {sessions_amount} sessions in package
            </S.SessionsCount>
            {/* {expires_at && ( */}
            <S.ExpiresAt>
              {expires_at
                ? `Expires at: ${new Date(expires_at).toLocaleDateString()}`
                : ''}
            </S.ExpiresAt>
          </Box>
        </Paper>
        <S.Price>
          <GoldLabel text={`$${price}`} variant={'h5'} />
        </S.Price>
      </S.Container>
    </Grid>
  );
};
