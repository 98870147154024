import { Controller, useFormContext } from 'react-hook-form';

import { Typography } from '@mui/material';
import {
  LocalizationProvider,
  renderDigitalClockTimeView,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import * as S from './FormTimePicker.styled';

interface FormTimePickerProps {
  disabled?: boolean;
  label: string;
  maxTime?: Date;
  minTime?: Date;
  minutesStep?: number;
  name: string;
  onChange?: (value: Date) => void;
  shouldDisableTime?: (time: Date) => boolean;
}

export const FormTimePicker = ({
  disabled,
  label,
  minTime,
  maxTime,
  minutesStep,
  name,
  onChange,
  shouldDisableTime,
}: FormTimePickerProps) => {
  const { control, setValue } = useFormContext();

  return (
    <S.Container>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <TimePicker
                disabled={disabled}
                disableIgnoringDatePartForTimeValidation
                label={label}
                minTime={minTime}
                maxTime={maxTime}
                minutesStep={minutesStep}
                shouldDisableTime={shouldDisableTime}
                skipDisabled
                slotProps={{
                  actionBar: {
                    actions: [],
                  },
                  field: {
                    clearable: true,
                  },
                }}
                viewRenderers={{
                  hours: renderDigitalClockTimeView,
                  minutes: null,
                  seconds: null,
                }}
                {...field}
                onChange={(value) => {
                  if (value === null) {
                    setValue(name, null);
                    return;
                  }

                  field.onChange(value);

                  if (onChange) {
                    onChange(value as Date);
                  }
                }}
                onError={() => {
                  field.onBlur();
                }}
                onAccept={() => {
                  field.onBlur();
                }}
              />
              {error && (
                <Typography
                  variant="helperText"
                  color="error"
                  className="error-message"
                >
                  <>{error?.message}</>
                </Typography>
              )}
            </>
          )}
        />
      </LocalizationProvider>
    </S.Container>
  );
};
