import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  areIntervalsOverlapping,
  format,
  isAfter,
  isBefore,
  isEqual,
} from 'date-fns';
import { capitalize, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';

import CloseIcon from 'assets/icons/cross.svg';
import DeleteIcon from 'assets/icons/delete.svg';

import { CustomButton } from 'components';
import { FormDatePicker } from 'components/HookFormWrappers/FormDatePicker/FormDatePicker';
import { FormMultiSelect } from 'components/HookFormWrappers/FormMultiSelect/FormMultiSelect';
import { FormTimePicker } from 'components/HookFormWrappers/FormTimePicker/FormTimePicker';
import { useAppDispatch } from 'hooks/useAppSelector';

import { RootState } from 'store/rootReducer';
import {
  addTimeSlots,
  getTimeSlots,
} from 'store/time-slots/time-slots.actions';
import { DATE_FORMAT, TIME_FORMAT_AM_PM } from 'utils/constants';

import { TIME_SLOTS_EDITOR_TYPES } from '../ScheduleForms.constants';
import * as SharedS from '../ScheduleForms.styled';
import {
  ADD_SCHEDULE_FORM_DEFAULT_VALUES,
  createTime,
  mapBuildingsForSelect,
  prepareDataForRequest,
} from './AddScheduleForm.helpers';
import { getAddScheduleFormSchema } from './AddScheduleForm.schema';
import * as S from './AddScheduleForm.styled';
import {
  AddScheduleFormValues,
  EditorTimeSlot,
  RenderScheduleArgs,
} from './AddScheduleForm.types';
import { DaysSelector } from './DaysSelector/DaysSelector';
import { SelectedInterval } from './IntervalsSelector/IntervalsSelector.types';
import { LocationServiceCombined } from './LocationServiceCombined/LocationServiceCombined';

interface TimeSlotsEditorProps {
  editorType: TIME_SLOTS_EDITOR_TYPES;
  handleCloseTimeSlotsModal: () => void;
  serviceOrSpecialistId: string;
  isSpecialistPage?: boolean;
}

export const AddScheduleForm = ({
  editorType,
  handleCloseTimeSlotsModal,
  serviceOrSpecialistId,
  isSpecialistPage,
}: TimeSlotsEditorProps) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { buildings } = useSelector((state: RootState) => state.buildings);
  const {
    timeSlotsList: { params },
  } = useSelector((state: RootState) => state.timeSlots);
  const [schedules, setSchedules] = useState<AddScheduleFormValues[]>([]);

  const addScheduleForm = useForm({
    mode: 'onBlur',
    resolver: yupResolver(getAddScheduleFormSchema(isSpecialistPage)),
    values: ADD_SCHEDULE_FORM_DEFAULT_VALUES,
  });

  const {
    reset,
    clearErrors,
    formState,
    getValues,
    setError,
    setValue,
    watch,
  } = addScheduleForm;

  const watchedStartDate = watch('start_date');
  const watchedStartTime = watch('start_time');
  const watchedEndDate = watch('end_date');
  const watchedEndTime = watch('end_time');
  const watchedMinDuration = watch('min_duration') as number;
  const watchedTimeslots = watch('timeslots');
  const watchedWeekdays = watch('weekdays');

  const minStartDateTime = useMemo(() => {
    const minDurationInMinutes = watchedMinDuration * 60;

    if (
      DateTime.now().hour >= 22 ||
      DateTime.now().plus({ minutes: minDurationInMinutes }).hour >= 22
    ) {
      return DateTime.now().startOf('day').plus({ days: 1 }).toJSDate();
    }

    return DateTime.now().startOf('day').toJSDate();
  }, [watchedMinDuration]);

  const minEndDateTime = useMemo(() => {
    if (!watchedStartTime) {
      setValue('end_time', null);

      return;
    }

    const minDurationInMinutes = watchedMinDuration * 60;

    return DateTime.fromJSDate(watchedStartTime)
      .plus({ minutes: minDurationInMinutes })
      .toJSDate();
  }, [setValue, watchedMinDuration, watchedStartTime]);

  const handleSubmitForm = async () => {
    if (schedules.length === 0) {
      enqueueSnackbar('Error: No schedules to add', {
        variant: 'error',
      });

      return;
    }

    const formValues = getValues();

    if (
      formValues.start_date ||
      formValues.end_date ||
      formValues.timeslots.length
    ) {
      enqueueSnackbar('Error: Please apply all schedules before saving', {
        variant: 'error',
      });

      return;
    }

    await Promise.all(
      schedules.map(async (schedule) => {
        const addTimeSlotsRequest = prepareDataForRequest(
          schedule,
          editorType,
          serviceOrSpecialistId,
          isSpecialistPage,
        );

        try {
          await dispatch(addTimeSlots(addTimeSlotsRequest)).unwrap();

          enqueueSnackbar('Time slots were added successfully', {
            variant: 'success',
          });
          handleCloseTimeSlotsModal();

          await dispatch(getTimeSlots({ ...params }));
        } catch (error) {
          enqueueSnackbar(`Error: ${(error as Error).message}`, {
            variant: 'error',
          });
        }
      }),
    );
  };

  const handleAddTimeSlot = () => {
    const currentTimeslots = watch('timeslots');

    if (!watchedStartDate || !watchedEndDate) {
      enqueueSnackbar('Error: Start and end dates are required', {
        variant: 'error',
      });
      return;
    }

    if (!watchedStartTime || !watchedEndTime) {
      enqueueSnackbar('Error: Start and end times are required', {
        variant: 'error',
      });
      return;
    }

    if (
      isBefore(watchedEndDate, watchedStartDate) ||
      isEqual(watchedEndDate, watchedStartDate)
    ) {
      setError('end_date', {
        message: 'End date should be later than start date',
      });

      return;
    }

    if (
      currentTimeslots.length > 0 &&
      currentTimeslots.some(({ start_time, end_time }) => {
        const intervalsAreOverlapping = areIntervalsOverlapping(
          {
            start: createTime(DateTime.fromJSDate(start_time)).valueOf(),
            end: createTime(DateTime.fromJSDate(end_time)).valueOf(),
          },
          {
            start: createTime(DateTime.fromJSDate(watchedStartTime)).valueOf(),
            end: createTime(DateTime.fromJSDate(watchedEndTime)).valueOf(),
          },
        );

        return intervalsAreOverlapping;
      })
    ) {
      enqueueSnackbar(
        'Error: The selected timeslot intervals overlap with previously selected timeslots',
        {
          variant: 'error',
        },
      );

      return;
    }

    const timeslot: EditorTimeSlot = {
      start_time: watchedStartDate as Date,
      end_time: watchedEndDate as Date,
    };

    if (currentTimeslots.some((item) => item === timeslot)) {
      return;
    }

    const newTimeslots = [...currentTimeslots, timeslot].sort(
      (a, b) => a.start_time.valueOf() - b.start_time.valueOf(),
    );

    clearErrors('end_date');
    setValue('timeslots', newTimeslots);
    setValue('repeat_checkbox', false);

    const formFieldsToReset: (keyof AddScheduleFormValues)[] = [
      'start_time',
      'end_time',
    ];

    formFieldsToReset.forEach((name) => setValue(name, null));
  };

  const handleDeleteSelectedInterval = useCallback(
    (startIsoString: string) => {
      setValue(
        'timeslots',
        getValues('timeslots').filter(
          (item: SelectedInterval) =>
            item.start_time.toISOString() !== startIsoString,
        ),
      );
    },
    [setValue, getValues],
  );

  const handleApplySchedule = (values: AddScheduleFormValues) => {
    const formErrors = Object.entries(formState.errors);

    if (formErrors.length > 0) {
      formErrors.forEach(([key, error]) => {
        enqueueSnackbar(`${key} - ${error.message}`, {
          variant: 'error',
        });
      });

      return;
    }

    setSchedules(uniq([...schedules, values]));

    reset({
      ...ADD_SCHEDULE_FORM_DEFAULT_VALUES,
      services_buildings: getValues('services_buildings'),
    });
  };

  const handleClearSchedule = ({
    preview,
    scheduleIndex,
  }: {
    preview: boolean;
    scheduleIndex?: number;
  }) => {
    if (preview) {
      reset({
        ...ADD_SCHEDULE_FORM_DEFAULT_VALUES,
        services_buildings: getValues('services_buildings'),
      });

      return;
    }

    if (scheduleIndex !== undefined) {
      setSchedules(
        schedules.filter((item) => item !== schedules[scheduleIndex]),
      );
    }
  };

  const renderSchedule = ({
    endDate,
    preview,
    scheduleIndex,
    startDate,
    timeslots,
    weekdays,
  }: RenderScheduleArgs) => {
    if (!startDate || !endDate || !timeslots.length) {
      return null;
    }

    const startDateDt = DateTime.fromJSDate(startDate);
    const endDateDt = DateTime.fromJSDate(endDate);

    return (
      <S.SelectedIntervals>
        <S.SelectedInterval>
          <div className="time-slot-date-range-container">
            <span className="time-slot-date-range">
              {startDateDt.toFormat(DATE_FORMAT)}
              {startDateDt.day !== endDateDt.day &&
                ` to ${format(endDate, DATE_FORMAT)}`}
            </span>
            <button>
              <img
                alt="Delete Time Slot"
                onClick={() => handleClearSchedule({ preview, scheduleIndex })}
                src={CloseIcon}
              />
            </button>
          </div>

          {weekdays.length > 0 && (
            <span className="time-slot-weekdays">
              Repeats on: {weekdays.map((day) => capitalize(day)).join(', ')}
            </span>
          )}

          {timeslots.map(
            ({ start_time, end_time }: SelectedInterval, index) => {
              return (
                <div className="time-slot" key={`time-slot-${index}`}>
                  <span className="time-slot-time-range">
                    <span>{format(start_time, TIME_FORMAT_AM_PM)}</span>
                    <span> &ndash; </span>
                    <span>{format(end_time, TIME_FORMAT_AM_PM)}</span>
                  </span>
                  {preview && (
                    <button type="button" className="delete-interval">
                      <img
                        src={DeleteIcon}
                        alt="Delete Time Slot"
                        onClick={() =>
                          handleDeleteSelectedInterval(start_time.toISOString())
                        }
                      />
                    </button>
                  )}
                </div>
              );
            },
          )}
          {preview && (
            <CustomButton
              additionalClass="apply-btn"
              color="primary"
              handleClick={addScheduleForm.handleSubmit(handleApplySchedule)}
              title="Apply"
              size="sm"
              variant="body1"
            />
          )}
        </S.SelectedInterval>
      </S.SelectedIntervals>
    );
  };

  /**
   * Check if a given datetime is within the allowed time range
   * (6am to 10pm) and if it doesn't overlap with any existing timeslots
   * on the same day.
   */
  const shouldDisableTime = ({
    datetime,
    name,
  }: {
    datetime: Date;
    name: keyof EditorTimeSlot;
  }) => {
    const dt = DateTime.fromJSDate(datetime);

    const dtTime = dt.toISOTime() as string;
    const sixAm = dt
      .set({ hour: 6, minute: 0, second: 0, millisecond: 0 })
      .toISOTime() as string;
    const tenPm = dt
      .set({ hour: 22, minute: 0, second: 0, millisecond: 0 })
      .toISOTime() as string;

    if (dtTime < sixAm || dtTime > tenPm) {
      return true;
    }

    const currentTimeslots = getValues('timeslots');

    const timeslotsOnSelectedDay = currentTimeslots.filter(
      (item) => item[name].getDay() === datetime.getDay(),
    );

    if (
      timeslotsOnSelectedDay.some(
        (item) => datetime >= item.start_time && datetime <= item.end_time,
      )
    ) {
      return true;
    }

    return false;
  };

  return (
    <S.Container>
      <S.Close onClick={handleCloseTimeSlotsModal}>
        <img src={CloseIcon} alt="" />
      </S.Close>
      {editorType === TIME_SLOTS_EDITOR_TYPES.AVAILABLE_TIME && (
        <S.Header>Add Working Time</S.Header>
      )}
      {editorType === TIME_SLOTS_EDITOR_TYPES.UNAVAILABLE_TIME && (
        <S.Header>Add Time-off</S.Header>
      )}
      <FormProvider {...addScheduleForm}>
        <S.ScrollableContainer>
          <SharedS.LocationWrapper>
            {buildings?.result?.length > 0 && !isSpecialistPage && (
              <>
                <SharedS.FormItemHeader>Location</SharedS.FormItemHeader>
                <FormMultiSelect
                  name="services_buildings"
                  label="Buildings"
                  items={mapBuildingsForSelect(buildings)}
                  hasSelectAllOption={true}
                  disabled={!buildings.result.length}
                />
              </>
            )}
            {buildings?.result?.length > 0 && isSpecialistPage && (
              <>
                <LocationServiceCombined
                  name="services_buildings"
                  buildings={buildings}
                  specialist_id={serviceOrSpecialistId}
                />
              </>
            )}
          </SharedS.LocationWrapper>

          <SharedS.DateAndTimeWrapper>
            <SharedS.FormItemHeader>Date and Time</SharedS.FormItemHeader>
            <SharedS.DoubleFormItem>
              <SharedS.DatePickerWrapper>
                <FormDatePicker
                  label="Start date"
                  name="start_date"
                  minDate={minStartDateTime}
                  onChange={(value) => {
                    if (watchedEndDate && isAfter(value, watchedEndDate)) {
                      setValue('end_date', null);
                    }
                  }}
                />
              </SharedS.DatePickerWrapper>
              <SharedS.DatePickerWrapper>
                <FormDatePicker
                  label="End date"
                  name="end_date"
                  minDate={watchedStartDate || DateTime.now().toJSDate()}
                />
              </SharedS.DatePickerWrapper>
            </SharedS.DoubleFormItem>

            <>
              <S.RepeatHeader>Select day(s) of the week</S.RepeatHeader>
              <DaysSelector name="weekdays" />
            </>

            <SharedS.DoubleFormItem>
              <SharedS.DatePickerWrapper>
                <FormTimePicker
                  disabled={!watchedStartDate}
                  label="Start Time"
                  name="start_time"
                  minutesStep={30}
                  onChange={(value) => {
                    const startDate = DateTime.fromJSDate(
                      watchedStartDate as Date,
                    ).set({
                      hour: DateTime.fromJSDate(value).hour,
                      minute: DateTime.fromJSDate(value).minute,
                    });

                    setValue('start_date', startDate.toJSDate());
                  }}
                  shouldDisableTime={(datetime) =>
                    shouldDisableTime({ datetime, name: 'start_time' })
                  }
                />
              </SharedS.DatePickerWrapper>
              <SharedS.DatePickerWrapper>
                <FormTimePicker
                  disabled={!watchedStartDate || !watchedEndDate}
                  label="End Time"
                  name="end_time"
                  minTime={minEndDateTime}
                  minutesStep={30}
                  onChange={(value) => {
                    const endDate = DateTime.fromJSDate(
                      watchedEndDate as Date,
                    ).set({
                      hour: DateTime.fromJSDate(value).hour,
                      minute: DateTime.fromJSDate(value).minute,
                    });

                    setValue('end_date', endDate.toJSDate());
                  }}
                  shouldDisableTime={(datetime) =>
                    shouldDisableTime({ datetime, name: 'end_time' })
                  }
                />
              </SharedS.DatePickerWrapper>
              <S.ButtonsWrapper>
                <CustomButton
                  title="Add"
                  color="primary"
                  disabled={
                    !watchedStartDate ||
                    !watchedEndDate ||
                    !watchedStartTime ||
                    !watchedEndTime
                  }
                  variant="buttonMedium"
                  size="sm"
                  handleClick={handleAddTimeSlot}
                />
              </S.ButtonsWrapper>
            </SharedS.DoubleFormItem>
          </SharedS.DateAndTimeWrapper>

          {/* <FormCheckbox name="repeat_checkbox" label="Repeat" size={20} /> */}
          {/* <SharedS.RepeatEveryWrapper>
                <S.RepeatHeader>Repeats every</S.RepeatHeader>
                <SharedS.DoubleFormItem>
                  <FormSelect
                    name="repeat_type"
                    label="Period"
                    items={REPEATS_TYPES_OPTIONS}
                  />
                  <FormSelect
                    name="repeat_every"
                    label="Count"
                    items={REPEATS_EVERY_OPTIONS}
                  />
                </SharedS.DoubleFormItem>
              </SharedS.RepeatEveryWrapper> */}

          <>
            {renderSchedule({
              endDate: watchedEndDate,
              preview: true,
              startDate: watchedStartDate,
              timeslots: watchedTimeslots,
              weekdays: watchedWeekdays,
            })}
            {schedules.map((schedule, scheduleIndex) =>
              renderSchedule({
                endDate: schedule.end_date,
                preview: false,
                scheduleIndex,
                startDate: schedule.start_date,
                timeslots: schedule.timeslots,
                weekdays: schedule.weekdays,
              }),
            )}
          </>

          <S.ButtonsWrapper>
            <CustomButton
              title="Cancel"
              color="secondary"
              variant="buttonMedium"
              size="sm"
              handleClick={handleCloseTimeSlotsModal}
            />
            <CustomButton
              disabled={schedules.length === 0}
              title="Save"
              color="primary"
              variant="buttonMedium"
              size="sm"
              handleClick={handleSubmitForm}
            />
          </S.ButtonsWrapper>
        </S.ScrollableContainer>
      </FormProvider>
    </S.Container>
  );
};
