import { TIME_SLOTS_EDITOR_TYPES } from '../ScheduleForms.constants';

export interface EditorTimeSlot {
  start_time: Date;
  end_time: Date;
}

export enum RepeatType {
  WEEK = 'week',
  MONTH = 'month',
}

export type RepeatEvery = 1 | 2 | 3;

export interface LocationServices {
  building: string;
  services: string[];
  id?: string;
}

export interface AddScheduleFormValues {
  end_date: Date | null;
  end_time: Date | null;
  min_duration?: number;
  no_end_date: boolean;
  repeat_checkbox: boolean;
  repeat_every: RepeatEvery;
  repeat_type: RepeatType;
  services_buildings: string[] | LocationServices[];
  start_date: Date | null;
  start_time: Date | null;
  status?: TIME_SLOTS_EDITOR_TYPES;
  timeslots: EditorTimeSlot[];
  weekdays: string[];
}

export interface AddTimeSlotsRequestValues
  extends Omit<
    AddScheduleFormValues,
    | 'services_buildings'
    | 'no_end_date'
    | 'start_date'
    | 'end_date'
    | 'timeslots'
    | 'start_time'
    | 'end_time'
  > {
  services_buildings: LocationServices[];
  start_date?: string;
  end_date?: string;
  timeslots: {
    start_time: string;
    end_time: string;
  }[];
  specialist_id?: string;
}

export interface RenderScheduleArgs {
  endDate: Date | null;
  preview: boolean;
  scheduleIndex?: number;
  startDate: Date | null;
  timeslots: EditorTimeSlot[];
  weekdays: string[];
}
