import styled from '@emotion/styled/macro';

export const Container = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  min-height: 300px;
  background-color: white;
  border-radius: 20px;
  padding: 40px 0px 20px 30px;
`;

export const ScrollableContainer = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding-right: 30px;
`;

export const Header = styled.div`
  font-family: 'GraphikMedium', sans-serif;
  font-size: 24px;
  margin-bottom: 24px;
`;

export const Close = styled.button`
  border: none;
  box-shadow: none;
  background: none;
  position: absolute;
  right: 30px;
  top: 35px;
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: auto !important;

  button {
    margin-left: 10px;
  }
`;

export const SelectDayWrapper = styled.div`
  margin-bottom: 24px;
`;

export const SelectedIntervals = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const SelectedInterval = styled.div`
  min-height: 2rem;
  display: flex;
  padding: 0.5rem;
  margin-bottom: 10px;
  align-items: start;
  justify-content: flex-start;
  position: relative;
  background-color: ${(props: any) => props.theme.palette.grey[200]};
  border-radius: 10px;
  flex-direction: column;

  & span {
    font-family: 'GraphikMedium', sans-serif;
  }

  .time-slot {
    align-items: center;
    display: flex;
    font-size: 15px;
    margin-bottom: 0.1rem;
    margin-top: 0.1rem;
  }

  .time-slot-date-range-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    > button {
      border: none;
      cursor: pointer;

      &:hover {
        background-color: ${(props: any) => props.theme.palette.grey[400]};
        border-radius: 25%;
      }
    }
  }

  .time-slot-date-range {
    font-size: 16px;
    margin-bottom: 0.5rem;
    text-decoration: underline;
  }

  .time-slot-weekdays {
    font-size: 15px;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .time-slot-time-range {
    display: flex;
    justify-content: space-evenly;
    margin-left: 0.5rem;
    width: 175px;
  }

  .delete-interval {
    background-color: ${(props: any) => props.theme.palette.grey[200]};
    border: none;
    cursor: pointer;
    position: absolute;
    left: 38%;

    &:hover {
      background-color: ${(props: any) => props.theme.palette.grey[300]};
      border-radius: 25%;
    }

    > img {
      color: red;
      width: 17px;
      height: 17px;
    }
  }

  .apply-btn {
    align-self: end;
  }
`;

export const DeleteInterval = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
`;

export const RepeatHeader = styled.div`
  font-family: 'GraphikMedium', sans-serif;
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 12px;
`;
