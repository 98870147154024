import { Fragment } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { SelectChangeEvent } from '@mui/material/Select';
import { addMinutes, isAfter, isToday } from 'date-fns';
import { useSnackbar } from 'notistack';

import { CustomButton } from 'components';
import { FormControlsPanel } from 'components/FormControlsPanel/FormControlsPanel';
import { FormCheckbox } from 'components/HookFormWrappers/FormCheckbox/FormCheckbox';
import { FormCheckboxGroup } from 'components/HookFormWrappers/FormCheckboxGroup/FormCheckboxGroup';
import { FormDatePicker } from 'components/HookFormWrappers/FormDatePicker/FormDatePicker';
import { FormMultiSelect } from 'components/HookFormWrappers/FormMultiSelect/FormMultiSelect';
import { FormSelect } from 'components/HookFormWrappers/FormSelect/FormSelect';
import { FormTimePicker } from 'components/HookFormWrappers/FormTimePicker/FormTimePicker';
import { useAppDispatch } from 'hooks/useAppSelector';

import { RootState } from 'store/rootReducer';
import { getServices } from 'store/services/services.actions';
import {
  getTimeSlots,
  GetTimeSlotsParams,
  updateTimeSlots,
} from 'store/time-slots/time-slots.actions';
import { MatchingSchedule, TimeSlot } from 'store/time-slots/time-slots.types';
import { Building } from 'types/buildings';
import {
  capitalizeFirstLetter,
  formatParsedDate,
  formatParsedTime,
} from 'utils/HelperFunctions';

import {
  mapBuildingsForSelect,
  mapServicesForSelect,
} from '../AddScheduleForm/AddScheduleForm.helpers';
import {
  REPEATS_EVERY_OPTIONS,
  REPEATS_TYPES_OPTIONS,
} from '../ScheduleForms.constants';
import * as SharedS from '../ScheduleForms.styled';
import {
  getEditScheduleValues,
  mapMatchingSchedulesForCheckboxes,
  prepareDataForRequest,
} from './EditScheduleForm.helpers';
import { getEditScheduleFormSchema } from './EditScheduleForm.schema';
import * as S from './EditScheduleForm.styled';
import { EditScheduleValues } from './EditScheduleForm.types';

interface EditScheduleFormProps {
  currentTimeSlot: TimeSlot;
  buildings: {
    result: Building[];
  };
  timeSlotListGetParams: GetTimeSlotsParams;
  navigateBack: () => void;
  serviceOrSpecialistId: string;
  isSpecialistPage: boolean;
}

export const EditScheduleForm = ({
  currentTimeSlot,
  buildings,
  timeSlotListGetParams,
  navigateBack,
  serviceOrSpecialistId,
  isSpecialistPage,
}: EditScheduleFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { servicesList, loading: isServicesLoading } = useSelector(
    (state: RootState) => state.services,
  );
  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(getEditScheduleFormSchema(isSpecialistPage)),
    values: getEditScheduleValues(currentTimeSlot, isSpecialistPage),
  });

  const {
    setValue,
    watch,
    formState: { isDirty },
  } = methods;
  const watchedStartDate = watch('start_date');
  const watchedEndDate = watch('end_date');

  const handleSubmitForm = (values: EditScheduleValues) => {
    dispatch(
      updateTimeSlots({
        formValues: prepareDataForRequest(
          values,
          serviceOrSpecialistId,
          isSpecialistPage,
        ),
        id: currentTimeSlot.id,
      }),
    )
      .unwrap()
      .then(() => {
        enqueueSnackbar('Time slots were updated successfully', {
          variant: 'success',
        });
        dispatch(
          getTimeSlots({
            ...timeSlotListGetParams,
            ...(!isSpecialistPage ? { service_id: id } : {}),
            ...(isSpecialistPage ? { specialist_id: id } : {}),
          }),
        );
        navigateBack();
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <S.Container>
        <S.EditSection>
          <S.CurrentTimeSlotWrapper>
            <SharedS.FormItemHeader>You are editing</SharedS.FormItemHeader>
            <S.TimeSlotTime>
              {capitalizeFirstLetter(currentTimeSlot.day_of_the_week)}{' '}
              {formatParsedTime(currentTimeSlot.start_time)} -{' '}
              {formatParsedTime(currentTimeSlot.end_time)}
            </S.TimeSlotTime>
            <S.TimeSlotInfo>
              {currentTimeSlot.buildings.map((building) => (
                <S.Building key={building.id}>
                  {building.building_code} {building.building_name}
                </S.Building>
              ))}
            </S.TimeSlotInfo>
            <S.TimeSlotInfo>
              {formatParsedDate(currentTimeSlot.start_date)} -{' '}
              {currentTimeSlot.end_date
                ? formatParsedDate(currentTimeSlot.end_date)
                : 'No end date'}
            </S.TimeSlotInfo>
            <S.TimeSlotInfo>
              Every {currentTimeSlot.repeat_every}{' '}
              {capitalizeFirstLetter(currentTimeSlot.repeat_type as string)}
            </S.TimeSlotInfo>
          </S.CurrentTimeSlotWrapper>
          <div>
            <SharedS.FormItemHeader>Matching Schedules</SharedS.FormItemHeader>
            <S.LeftSectionHeaderHint>
              There are similar time schedules on other days.
              <br /> You can select schedule to add to editing
            </S.LeftSectionHeaderHint>
            <div>
              {currentTimeSlot.matching_schedules?.length ? (
                <FormCheckboxGroup
                  options={mapMatchingSchedulesForCheckboxes(
                    currentTimeSlot.matching_schedules as MatchingSchedule[],
                  )}
                  name="matching_schedules"
                  size={24}
                />
              ) : (
                'No matching schedules'
              )}
            </div>
          </div>
        </S.EditSection>
        <S.EditSection>
          <S.RightFormWrapper>
            {buildings.result.length && !isSpecialistPage && (
              <SharedS.LocationWrapper>
                <Fragment>
                  <SharedS.FormItemHeader>Location</SharedS.FormItemHeader>
                  <FormMultiSelect
                    name="buildings"
                    label="Buildings"
                    disabled={!buildings.result.length}
                    items={mapBuildingsForSelect(buildings)}
                    hasSelectAllOption={true}
                  />
                </Fragment>
              </SharedS.LocationWrapper>
            )}
            {Boolean(buildings?.result?.length) && isSpecialistPage && (
              <SharedS.LocationWrapper>
                <Fragment>
                  <SharedS.FormItemHeader>
                    Location and service
                  </SharedS.FormItemHeader>
                  <SharedS.FormItem>
                    <FormSelect
                      name="building"
                      label="Building"
                      disabled={!buildings.result.length}
                      items={mapBuildingsForSelect(buildings)}
                      onChange={(event: SelectChangeEvent) => {
                        dispatch(
                          getServices({
                            building_id: event.target.value,
                            specialist: serviceOrSpecialistId,
                          }),
                        );
                      }}
                    />
                  </SharedS.FormItem>
                  <FormMultiSelect
                    name="services"
                    label="Services"
                    items={mapServicesForSelect(servicesList.list)}
                    disabled={
                      isServicesLoading || !servicesList.list.result.length
                    }
                    hasSelectAllOption={true}
                  />
                </Fragment>
              </SharedS.LocationWrapper>
            )}
            <SharedS.DateAndTimeWrapper>
              <SharedS.FormItemHeader>Date and Time</SharedS.FormItemHeader>
              <SharedS.DoubleFormItem>
                <SharedS.DatePickerWrapper>
                  <FormDatePicker
                    name="start_date"
                    label="Start date"
                    onChange={(value) => {
                      if (watchedEndDate && isAfter(value, watchedEndDate)) {
                        setValue('end_date', null);
                      }
                    }}
                  />
                </SharedS.DatePickerWrapper>
                <SharedS.DatePickerWrapper>
                  <FormDatePicker
                    name="end_date"
                    label="End date"
                    minDate={watch('start_date') as Date}
                    disabled={
                      !(watch('start_date') as Date) || watch('no_end_date')
                    }
                  />
                  <FormCheckbox
                    name="no_end_date"
                    label="No end date"
                    size={20}
                    onChange={() => {
                      setValue('end_date', null);
                    }}
                  />
                </SharedS.DatePickerWrapper>
              </SharedS.DoubleFormItem>
              <SharedS.DoubleFormItem>
                <SharedS.DatePickerWrapper>
                  <FormTimePicker
                    name="start_time"
                    label="Start time"
                    minutesStep={15}
                    minTime={
                      isToday(watchedStartDate) &&
                      watchedEndDate &&
                      isToday(watchedEndDate)
                        ? new Date()
                        : undefined
                    }
                  />
                </SharedS.DatePickerWrapper>
                <SharedS.DatePickerWrapper>
                  <FormTimePicker
                    name="end_time"
                    label="End time"
                    minutesStep={15}
                    minTime={addMinutes(watch('start_time') as Date, 15)}
                    disabled={!(watch('start_time') as Date)}
                  />
                </SharedS.DatePickerWrapper>
              </SharedS.DoubleFormItem>
            </SharedS.DateAndTimeWrapper>
            <SharedS.RepeatEveryWrapper>
              <SharedS.FormItemHeader>Repeats every</SharedS.FormItemHeader>
              <SharedS.DoubleFormItem>
                <FormSelect
                  name="repeat_every"
                  label="Count"
                  items={REPEATS_EVERY_OPTIONS}
                />
                <FormSelect
                  name="repeat_type"
                  label="Period"
                  disabled={true}
                  items={REPEATS_TYPES_OPTIONS}
                />
              </SharedS.DoubleFormItem>
            </SharedS.RepeatEveryWrapper>
          </S.RightFormWrapper>
        </S.EditSection>
      </S.Container>
      <FormControlsPanel>
        <Fragment>
          <CustomButton
            title="Cancel"
            color="gray"
            variant="buttonMedium"
            handleClick={navigateBack}
          />
          <CustomButton
            title="Save"
            color="primary"
            variant="buttonMedium"
            disabled={!isDirty}
            handleClick={methods.handleSubmit(handleSubmitForm)}
          />
        </Fragment>
      </FormControlsPanel>
    </FormProvider>
  );
};
