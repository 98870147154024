import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useSnackbar } from 'notistack';

import { createPackage } from 'store/packages/packages.actions';

import { CustomButton } from '../../../components';
import { BaseFormInput } from '../../../components/HookFormWrappers/BaseFormInput/BaseFormInput';
import { useAppDispatch } from '../../../hooks/useAppSelector';
import { packageEditorSchema } from './PackageEditor.schema';
import * as S from './PackageEditor.styled';
import { PackageEditorValues } from './PackageEditor.types';
import { FormDatePicker } from 'components/HookFormWrappers/FormDatePicker/FormDatePicker';

interface CreatePackageModalProps {
  closeModal: () => void;
}

export const PackageEditor = ({ closeModal }: CreatePackageModalProps) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const methods = useForm<PackageEditorValues>({
    mode: 'onChange',
    resolver: yupResolver(packageEditorSchema),
    values: {
      name: '',
      price: '',
      sessions_amount: '',
      expires_at: '',
    },
  });

  const handleSubmitForm = (values: PackageEditorValues) => {
    dispatch(createPackage({ id: id as string, data: values }))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Package was created.', { variant: 'success' });
        closeModal();
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error' });
      });
  };

  return (
    <FormProvider {...methods}>
      <S.FormWrapper>
        <S.FormItem>
          <BaseFormInput name="name" label="Package Name" />
        </S.FormItem>
        <S.FormItem>
          <BaseFormInput name="price" label="Price" />
        </S.FormItem>
        <S.FormItem>
          <BaseFormInput name="sessions_amount" label="Sessions Amount" />
        </S.FormItem>
        <S.FormItem>
          <FormDatePicker name="expires_at" label="Expires At" />
        </S.FormItem>
        <S.ButtonsWrapper>
          <CustomButton
            title="Cancel"
            color="gray"
            variant="buttonMedium"
            size="sm"
            handleClick={() => closeModal()}
          />
          <CustomButton
            title="Create"
            color="primary"
            variant="buttonMedium"
            size="sm"
            handleClick={methods.handleSubmit(handleSubmitForm)}
          />
        </S.ButtonsWrapper>
      </S.FormWrapper>
    </FormProvider>
  );
};
